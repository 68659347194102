import './App.css';
import {useEffect, useState} from "react";


const machineList = () => {
  return (<img src={'maschinenliste.png'} style={{maxHeight:'100vh', width:'auto'}}/>);
}

const halle = () => {
  return (
      <div className="container">
          <div className="map" style={{flex: 1}}>
              <img src={'halle.png'} style={{maxWidth: '100%'}}/>
              <span id="marker1">1</span>
              <span id="marker2">2</span>
              <span id="marker3">3</span>
              <span id="marker4">4</span>
              <span id="marker5">5</span>
              <span id="marker6">6</span>
              <span id="marker7">7</span>
              {/*<span id="marker8">8</span> */}
              <span id="marker9">8</span>
              <span id="marker10">9</span>
              <span id="marker11">10</span>
              <span id="marker12">11</span>
              <span id="marker13">12</span>
              <span id="location"></span>
          </div>
          <div style={{minWidth: '300px'}}>
              <ol>
                  <li>Zoller Werkzeugverwaltung</li>
                  <li>Erfolgreich Innovieren</li>
                  <li>Jokisch KSS</li>
                  <li>Makino</li>
                  <li>Inkubator Werkzeugbau</li>
                  <li>Tool2Share</li>
                  <li>Meusburger</li>
                  {/* <li>HoloLens 2</li> */}
                  <li>Try-Out App</li>
                  <li>Digitale Werkzeugkarte</li>
                  <li>Nachhaltigkeit</li>
                  <li>IIoT-Dashboard</li>
                  <li>AddUp</li>
              </ol>
          </div>
      </div>
  )
}


function App() {

  const [showList, setShowList] = useState(false);

  useEffect(() => {
      const interval = setInterval(() => setShowList(!showList), 1000 * 30); //30 seconds
      return () => {
          clearInterval(interval);
      };
  }, [showList]);


  return (
    <div className="App">
        {showList ? machineList() : halle()}
    </div>
  );
}

export default App;
